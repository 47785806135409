<template>
  <div class="container">
    <header>
      <div class="title-box">
        <div class="title">{{ leaguesName }}</div>
        <img class="bgimg" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0187226f-306f-733b-dcd7-c0d6469ff80f?x-oss-process=style/origin" alt="bgimg" />
      </div>
    </header>
    <main>
      <div v-for="(squad, index) in squads" :key="squad.name" class="item">
        <div class="box">
          <div v-if="index < 3" :class="'medal-' + (index + 1)" />
          <div v-else class="rank">{{ index + 1 }}</div>
          <img class="logo" :src="squad.avatarUrl" alt="logo">
          <div class="info">
            <div class="name">{{ squad.name }}</div>
            <div class="desc">胜场{{ squad.win }} ｜ 总得分{{ squad.score }}</div>
          </div>
        </div>
        <img v-if="index < 3" class="bgimg" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018725ef-5ebe-3ac4-260e-57573cd23538?x-oss-process=style/origin" alt="logo">
      </div>
    </main>
    <footer>
      <img class="qrcode" :src="qrcode" alt="">
    </footer>
  </div>
</template>

<script>
import $fetch from '@/utils/fetch.js';
export default {
  name: "leaguesRank",
  data() {
    return {
      campaignId: "",
      leaguesName: "",
      squads: "",
      qrcode: "",
    }
  },
  created() {
    let { campaignId, leaguesName, squads } = this.$route.query;
    this.campaignId = campaignId;
    this.leaguesName = leaguesName;
    this.squads = JSON.parse(squads).slice(0,8);
    this.getWechatCode(campaignId);
  },
  methods: {
    /** 获取活动的小程序码 */
    getWechatCode(campaignId) {
      const _ = this;
      $fetch
        .post("SharePost", {
          campaignId,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.mini_program_code_image;
        });
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1;

.container {
  @include column(flex-satrt, center);
  background-color: #051718;

  > header {
    position: relative;
    width: 100%;
    height: px2rem(380 * $multipleCount);
    background: url('https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01873752-46a5-088b-a164-31633af0e959?x-oss-process=style/origin') no-repeat center top / cover;

    .title-box {
      @include row(flex-start, center);
      position: absolute;
      left: px2rem(32 * $multipleCount);
      top: px2rem(316 * $multipleCount);
      width: px2rem(686 * $multipleCount);
      height: px2rem(64 * $multipleCount);
      overflow: hidden;

      > .title {
        @include ellipsis;
        flex: none;
        max-width: calc(100% - px2rem(48 * $multipleCount));
        font-size: px2rem(32 * $multipleCount);
        line-height: px2rem(64 * $multipleCount);
        color: #00ff73;
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        margin-left: px2rem(24 * $multipleCount);
      }

      > .bgimg {
        height: px2rem(32 * $multipleCount);
        margin-left: px2rem(-30 * $multipleCount);
      }
    }
  }

  > main {
    width: px2rem(686 * $multipleCount);

    > .item {
      position: relative;
      width: 100%;
      height: px2rem(144 * $multipleCount);
      overflow: hidden;

      &:nth-child(n + 4) {
        background: linear-gradient(90deg, #F5FFFE 0%, #FFFFFF 100%);
      }

      .box {
        @include row(flex-start, center);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        > [class^=medal] {
          width: px2rem(76 * $multipleCount);
          height: px2rem(64 * $multipleCount);
          margin-left: px2rem(20 * $multipleCount);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }

        > .medal-1 {
          background-image: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018722a0-0a5b-5577-e011-d33222a65bf4?x-oss-process=style/origin");
        }

        > .medal-2 {
          background-image: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018722a0-0a5b-6846-1532-816f2e831517?x-oss-process=style/origin");
        }

        > .medal-3 {
          background-image: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018722a0-0a5b-608b-7bf6-95c0b6c46c65?x-oss-process=style/origin");
        }

        > .rank {
          position: relative;
          width: px2rem(76 * $multipleCount);
          height: px2rem(64 * $multipleCount);
          line-height: px2rem(64 * $multipleCount);
          color: #000;
          text-align: center;
          font-size: px2rem(32 * $multipleCount);
          margin-left: px2rem(20 * $multipleCount);
          font-family: DINAlternate-Bold;
        }

        > .logo {
          width: px2rem(96 * $multipleCount);
          height: px2rem(96 * $multipleCount);
          margin-left: px2rem(16 * $multipleCount);
          border-radius: 50%;
        }

        > .info {
          @include column;
          width: px2rem(300 * $multipleCount);
          margin-left: px2rem(16 * $multipleCount);
          overflow: hidden;

          > .name {
            @include ellipsis;
            width: 100%;
            font-size: px2rem(32 * $multipleCount);
            line-height: px2rem(48 * $multipleCount);
            color: #111;
            font-family: PingFangSC-Semibold;
          }

          > .desc {
            @include ellipsis;
            width: 100%;
            font-size: px2rem(28 * $multipleCount);
            line-height: px2rem(48 * $multipleCount);
            color: #a3a3a3;
            font-family: PingFangSC-Regular;
          }
        }
      }

      &:first-child > .box {
        background: linear-gradient(to right, #ffe7c5, #fffdf8);
      }

      &:nth-child(2) > .box {
        background: linear-gradient(to right, #d7dadd, #fefefe);
      }

      &:nth-child(3) > .box {
        background: linear-gradient(to right, #ffece5, #fffdfc);
      }

      > .bgimg {
        position: absolute;
        top: px2rem(16 * $multipleCount);
        right: px2rem(32 * $multipleCount);
        width: px2rem(128 * $multipleCount);
        height: px2rem(128 * $multipleCount);
        opacity: .1;
      }
    }
  }

  > footer {
    position: relative;
    width: 100%;
    height: px2rem(260 * $multipleCount);
    background: url('https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01873752-46a1-6a65-b1a8-38dac31f24ba?x-oss-process=style/origin') no-repeat center top / cover;

    .qrcode {
      position: absolute;
      right: px2rem(36 * $multipleCount);
      bottom: px2rem(68 * $multipleCount);
      width: px2rem(160 * $multipleCount);
      height: px2rem(160 * $multipleCount);
    }
  }
}
</style>
